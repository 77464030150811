import React from "react";
import Girl from "../../../assets/hero/girl.png";
import Boy from "../../../assets/hero/boy.png";
import styles from "./hero.module.scss";

export default function Images() {
  return (
    <>
      <div className={`${styles.bgboy} rounded-4 `}>
        <img
          style={{
            position: "absolute",
            bottom: "40px",
            left: "0",
            width: "100%",
          }}
          alt="boy"
          src={Boy}
        />
      </div>

      <div className={`${styles.bggirl} rounded-4`}>
        <img
          style={{
            position: "absolute",
            bottom: "10%",
            right: "0%",
            width: "90%",
          }}
          alt="girl"
          src={Girl}
        />
      </div>
    </>
  );
}
