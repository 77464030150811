import React from "react";
import styles from "./testimonials.module.scss";
export default function Reviews({ reviews }) {
  return (
    <div>
      <div className={`row ${styles.testimonials}`}>
        {reviews.map((review, index) => (
          <div key={index} className="bg-primary p-2 rounded-4">
            <p>{review.review}</p>
            <h5 className="mt-2">{review.name}</h5>
          </div>
        ))}
      </div>
    </div>
  );
}
