import React from "react";
import { motion } from "framer-motion";
import styles from "./usp.module.scss";

export default function Infobox({ info, setActiveSlide }) {
  const fadeUpVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0 },
  };

  return (
    <div className="row align-items-start mt-2">
      <div className="col-2">
        <button
          onClick={() => setActiveSlide(info.slide - 1)}
          className={`bg-secondary d-flex btn rounded-3 align-items-center justify-content-center ${
            styles.number
          } ${info.active === info.slide ? styles.active : ""}`}
          style={{ cursor: "pointer" }}
        >
          {info.slide}
        </button>
      </div>
      {info.active === info.slide && (
        <motion.div
          className="col-10"
          initial="hidden"
          animate="visible"
          exit="hidden"
          variants={fadeUpVariants}
          transition={{ duration: 1, ease: "easeInOut" }}
        >
          <motion.h3
            initial="hidden"
            animate="visible"
            variants={fadeUpVariants}
            transition={{ duration: 0.5, ease: "easeOut", delay: 0.7 }}
          >
            <strong>{info.title}</strong>
          </motion.h3>
          <motion.p
            initial="hidden"
            animate="visible"
            variants={fadeUpVariants}
            transition={{ duration: 0.5, ease: "easeOut", delay: 0.8 }}
          >
            {info.description}
          </motion.p>
        </motion.div>
      )}
    </div>
  );
}
