import React from "react";

export default function MessageBubble({ message }) {
  return (
    <div>
      <div
        className="position-relative bg-blue text-light rounded-4 p-1"
        style={{
          width: "fit-content",
        }}
      >
        <p className="fw-normal">{message}</p>
      </div>
    </div>
  );
}
