import React from "react";
import Infobox from "../../utils/Infobox.tsx";
import Reviews from "./Reviews.tsx";
import Man from "../../../assets/testimonials/man.png";
export default function Testimonials() {
  const reviews = [
    {
      name: "Alex J., Lead Developer at SecureWeb Solutions",
      rating: 5,
      review:
        "Implementing the MFA plugin for our Strapi CMS was a game-changer. The no-code setup made integration seamless, and now we have peace of mind knowing our backend is secure with multiple authentication options. It's a must-have for any developer looking to enhance security!",
    },
  ];

  return (
    <div className="py-20 container col-xxl-12 w-75 mx-auto">
      <div className="row">
        <div className="col-md-6">
          <Infobox
            info={{
              title: "Ready to secure your Strapi CMS?",
              description:
                "Join thousands of developers who trust our multi-factor authentication plugin to protect their Strapi backend. Get started today with an easy, no-code setup and robust security features.",
              rating: 5,
              button: "Join the beta test",
              link: { to: "#join-beta", text: "Join the Headlockr beta test" },
            }}
          />
        </div>
        <div className="col-md-6">
          <Reviews reviews={reviews} />
        </div>
      </div>
      <img
        alt="man"
        style={{
          width: "300px",
          height: "auto",
          position: "absolute",
          left: "50%",
          bottom: "150px",
          transform: "translate(-50%, 50%)",
        }}
        src={Man}
      />
    </div>
  );
}
