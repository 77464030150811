import React, { useState } from "react";
import Logo from "../../../../assets/logo/logo.png";
import { motion } from "framer-motion";

export default function Secure({ active }) {
  const [otp, setOtp] = useState(Array(6).fill(""));

  const handleChange = (element, index) => {
    if (isNaN(element.value)) return;
    let newOtp = [...otp];
    newOtp[index] = element.value;
    setOtp(newOtp);

    if (element.nextSibling && element.value !== "") {
      element.nextSibling.focus();
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    alert("OTP Submitted: " + otp.join(""));
  };

  const fadeVariants = {
    hidden: { opacity: 0, transition: { duration: 0.7, ease: "easeInOut" } },
    visible: { opacity: 1, transition: { duration: 0.7, ease: "easeInOut" } },
  };

  return (
    <motion.div
      style={{ overflow: "hidden" }}
      animate={{
        backgroundColor: active === 2 ? "#25293C" : "#2F3349",
      }}
      transition={{ duration: 0.8, ease: "easeOut", delay: 0 }}
      className="d-flex justify-content-center position-relative align-items-center h-100 rounded-4 overflow-hidden p-2"
    >
      {/* Background overlay */}
      <motion.div
        className="rounded-4"
        style={{
          position: "absolute",
          top: "0px",
          left: "0px",
          backgroundColor: "#2F3349",
          width: "100%",
          height: "100%",
          opacity: 1,
        }}
        animate={active === 2 ? "hidden" : "visible"}
        variants={fadeVariants}
      />

      <motion.div
        style={{ maxWidth: "400px", width: "100%" }}
        animate={active === 2 ? "visible" : "hidden"}
        variants={fadeVariants}
      >
        <motion.img
          style={{ width: "150px" }}
          src={Logo}
          alt="Logo"
          className="d-block mx-auto mb-4"
          animate={active === 2 ? "visible" : "hidden"}
          variants={fadeVariants}
        />

        <motion.h3
          className="mb-4 text-center fw-normal"
          animate={active === 2 ? "visible" : "hidden"}
          variants={fadeVariants}
        >
          2-step verification
        </motion.h3>

        <motion.p
          className="text-center mb-4 text-white"
          animate={active === 2 ? "visible" : "hidden"}
          variants={fadeVariants}
        >
          2-step verification has been enabled for this account. Please enter
          the one-time code from your authenticator app.
        </motion.p>

        <motion.form
          onSubmit={handleSubmit}
          animate={active === 2 ? "visible" : "hidden"}
          variants={fadeVariants}
        >
          <div className="d-flex justify-content-between mb-4">
            {otp.map((data, index) => (
              <motion.input
                className="form-control text-center mx-1"
                type="text"
                maxLength="1"
                key={index}
                value={data}
                onChange={(e) => handleChange(e.target, index)}
                onFocus={(e) => e.target.select()}
                style={{ width: "50px" }}
                animate={active === 2 ? "visible" : "hidden"}
                variants={fadeVariants}
              />
            ))}
          </div>

          <motion.div
            className="d-flex w-100 justify-content-center"
            animate={active === 2 ? "visible" : "hidden"}
            variants={fadeVariants}
          >
            <button type="submit" className="btn btn-tertiary w-100">
              Verify
            </button>
          </motion.div>
        </motion.form>

        <motion.div
          className="text-center mt-3"
          animate={active === 2 ? "visible" : "hidden"}
          variants={fadeVariants}
        >
          <a href="/" className="text-decoration-none text-primary">
            Try another method to verify
          </a>
        </motion.div>
      </motion.div>
    </motion.div>
  );
}
