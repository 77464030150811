import React from "react";
import "./faq.module.scss";

export default function Faq() {
  const faqs = [
    {
      question: "What is Headlockr?",
      answer:
        "Headlocks is a multifactor authentication plugin built for Strapi. It supports multiple authentication methods, including SMS, email, and TOTP. It's plug and play, easy to use, and highly customizable.",
    },
    {
      question: "How do I install Headlockr?",
      answer:
        "To install Headlockr, you will need to download the plugin via npm and install it in your Strapi project. In order to be able to download the software you'll need a valid license to do so. After installation you can configure the plugin settings and enable it for the desired authentication methods. We do have dedicated documentation https://docs.headlockr.io/  where you can follow the installation guide step by step.",
    },
    {
      question: "What is MFA?",
      answer:
        "MFA stands for Multi-Factor Authentication. It is a security feature that requires more than one method of authentication from independent categories of credentials to verify the user's identity for a login or other transaction.",
    },
    {
      question: "How does MFA work?",
      answer:
        "MFA works by requiring two or more of the following authentication methods: something you know (like a password), something you have (like a phone or hardware token), or something you are (like a fingerprint).",
    },
    {
      question: "What are the benefits of MFA?",
      answer:
        "MFA provides an additional layer of security to protect your accounts and data from unauthorized access. It reduces the risk of unauthorized access, credential theft, and compliance risks.",
    },
    {
      question: "How do I set up MFA?",
      answer:
        "To set up MFA, you need to enable it in the settings of your account or application. You can choose from various authentication methods like SMS, TOTP, and backup codes.",
    },
    {
      question: "What if I lose my MFA device?",
      answer:
        "If you lose access to your MFA device, you may be locked out of your account. As a last resort you can use backup codes to regain access to your account. Make sure to store your backup codes in a safe place. In case you lose your backup codes, you can contact your administrator or customer support.",
    },
    {
      question: "What is the difference between MFA and 2FA?",
      answer:
        "MFA and 2FA are often used interchangeably, but there is a subtle difference between the two. MFA stands for Multi-Factor Authentication, which requires two or more methods of authentication from independent categories of credentials. 2FA stands for Two-Factor Authentication, which requires two methods of authentication from two different categories of credentials.",
    },
    {
      question: "Is MFA secure?",
      answer:
        "MFA is considered a best practice for securing your accounts and data from unauthorized access. It adds an extra layer of security by requiring multiple methods of authentication. While no security measure is foolproof or 100% secure, MFA significantly reduces the risk of unauthorized access, credential theft, and compliance risks.",
    },
    {
      question: "Can MFA be bypassed?",
      answer:
        "MFA can be bypassed in some cases, but it significantly reduces the risk of unauthorized access and credential theft. It is not immune to attacks though. Some sophisticated attackers may be able to bypass MFA through social engineering, phishing, or other methods. It is important to remain vigilant and follow best practices for securing your accounts. To enhance security, you can use additional security measures like strong passwords, regular software updates, and security monitoring.",
    },
  ];

  return (
    <div className="faq-container w-75 container">
      <h2 className="text-center mb-4">
        <span>✨</span> Frequently Asked Questions <span>✨</span>
      </h2>

      <div className="accordion" id="faqAccordion">
        {faqs.map((faq, index) => (
          <div className=" mb-1" key={index}>
            <h2 className="accordion-header" id={`heading${index}`}>
              <button
                className="accordion-button rounded-3 collapsed bg-secondary text-light"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target={`#collapse${index}`}
                aria-expanded="false"
                aria-controls={`collapse${index}`}
              >
                {faq.question}
              </button>
            </h2>
            <div
              id={`collapse${index}`}
              className="accordion-collapse collapse rounded-bottom-3"
              aria-labelledby={`heading${index}`}
              data-bs-parent="#faqAccordion"
            >
              <div className="accordion-body bg-secondary text-light">
                {faq.answer}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
