import React from "react";
import { Link } from "@remix-run/react";

export default function Infobox({ info }) {
  const stars = Array(info.rating).fill("⭐");

  return (
    <div>
      <h2>{info.title}</h2>
      <p className="py-1">{info.description}</p>
      <div className="d-flex py-2">
        {stars.map((star, index) => (
          <img
            src="https://img.icons8.com/?size=100&id=85185&format=png&color=EBFF00"
            alt="rating"
            key={index}
            style={{ width: "20px", height: "20px", marginRight: "4px" }}
          />
        ))}
      </div>
      <Link to={info?.link?.to} className="btn btn-tertiary">
        {info.button}
      </Link>
    </div>
  );
}
