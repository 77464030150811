import React, { useState } from "react";
import Benefit1 from "../../../assets/benefits/benefit1.webp";
import Benefit2 from "../../../assets/benefits/benefit2.webp";
import Benefit3 from "../../../assets/benefits/benefit3.webp";
import Benefit4 from "../../../assets/benefits/benefit4.png";
import Benefit5 from "../../../assets/benefits/benefit5.webp";
import Benefit6 from "../../../assets/benefits/benefit6.webp";

export default function Benefits() {
  const [activeIndex, setActiveIndex] = useState(0);

  const benefits = [
    {
      title: "Enhanced security against unauthorized access",
      image: Benefit1,
      description:
        " Multi-factor Authentication (MFA) enhances security by adding multiple layers of protection, making unauthorized access significantly more difficult. By requiring multiple forms of identity verification, such as a password, a one-time code, or biometrics, MFA protects your system from common security threats like brute-force attacks, phishing attempts, and man-in-the-middle attacks. This ensures that only users with valid credentials, in addition to a secondary authentication method, can access your Strapi backend, providing a highly secure environment for sensitive data and administrative functions.",
      short_description:
        "With Multi-factor Authentication (MFA), your system is protected against brute-force, phishing, and man-in-the-middle attacks, ensuring that only authorized users can access your Strapi backend.",
    },
    {
      title: "Protection against credential theft",
      image: Benefit2,
      description:
        "In today’s digital environment, credential theft is a prevalent risk as attackers use stolen passwords to gain unauthorized access to systems. MFA significantly reduces this threat by requiring an additional verification step, beyond the traditional username and password, for each user. Even if an attacker obtains a user's login credentials, they won’t be able to gain access without passing the secondary authentication. This makes MFA an effective defense against a range of security threats, such as social engineering and spear-phishing attacks, by adding a strong layer of protection to your Strapi backend.",
      short_description:
        "MFA adds an extra layer of security to your Strapi backend, making it harder for attackers to gain unauthorized access by requiring multiple forms of verification.",
    },
    {
      title: "Compliance with industry standards",
      image: Benefit3,
      description:
        "Adhering to established security standards is crucial for organizations, especially when handling sensitive data. Many industry standards, including NIST, GDPR, and SOC 2, recommend or mandate MFA as part of their compliance frameworks to ensure the protection of user data and maintain trust. By implementing MFA within your Strapi backend, you align with these stringent guidelines, making your infrastructure more robust and compliant with industry regulations. This also demonstrates your commitment to upholding high-security standards, giving clients and stakeholders confidence in your system's integrity.",
      short_description:
        "MFA is a recommended security best practice by industry standards such as NIST and GDPR, ensuring that your Strapi backend meets the highest security requirements.",
    },
    {
      title: "Easy integration with no-code setup",
      image: Benefit4,
      description:
        " Security doesn’t have to be complicated, and our MFA plugin is designed with that in mind. The no-code setup means that even developers with minimal experience can configure and deploy MFA in their Strapi projects without needing extensive knowledge of complex security protocols. With intuitive configuration options, our plugin provides a straightforward integration process, allowing you to add MFA with just a few clicks. This empowers teams to enhance their security posture without investing significant time or resources into setup, ensuring a hassle-free experience for developers at any skill level.",
      short_description:
        "Our MFA plugin is designed for developers of all skill levels, with an easy, no-code setup that allows you to quickly integrate MFA into your Strapi project without any hassle.",
    },
    {
      title: "Robust security features for peace of mind",
      image: Benefit5,
      description:
        "Our MFA plugin is equipped with multiple authentication options, including SMS-based verification, Time-Based One-Time Passwords (TOTP), and email verification, giving you flexibility to choose the method that best suits your needs. These options enable stronger protection for your backend, ensuring that only authorized users can gain access. The plugin’s security features are designed to adapt to evolving security requirements, making it easy to scale as your user base grows. This robust suite of features provides peace of mind, knowing that your backend is well-protected against unauthorized access and data breaches.",
      short_description:
        "Our MFA plugin offers a range of security features, including SMS, TOTP, and email verification, to give you peace of mind knowing that your Strapi backend is secure.",
    },
    {
      title: "Effortless user experience for seamless authentication",
      image: Benefit6,
      description:
        "Security should not come at the cost of user experience. Our MFA plugin is designed to be as unobtrusive as possible, providing an authentication process that integrates seamlessly with your users’ workflow. The plugin offers an adaptable authentication experience, allowing users to access your Strapi backend without unnecessary friction, while still ensuring high security. Whether accessing the backend for administrative tasks or content management, users will experience a smooth, intuitive process that doesn’t compromise on security, making it easy and enjoyable to use your Strapi application.",
      short_description:
        "MFA is designed to provide an effortless user experience, with seamless authentication that adapts to your workflow, making it easy for users to access your Strapi backend securely.",
    },
  ];

  return (
    <div>
      <h2>
        Built for <span className="text-tertiary">Performance </span>. <br />
        Designed for <span className="text-tertiary">Security</span>.
      </h2>
      <p className="w-lg-50 py-2">
        From admin panel to API, every part of your Strapi project is protected.
        Multi-factor authentication that’s effortless to set up and powerful
        enough to keep your APIs secure. Whether you use SMS, TOTP, or email,
        our plugin adapts to your workflow.
      </p>

      {/* Main layout */}
      <div className="row mt-4">
        {/* Accordion (always shown) */}
        <div className="col-md-6">
          <div className="accordion" id="benefitsAccordion">
            {benefits.map((benefit, index) => (
              <div
                className={`accordion-item border-0 bg-secondary mb-2 rounded-4`}
                key={index}
              >
                <h2 className="accordion-header" id={`heading${index}`}>
                  <button
                    className={`accordion-button ${
                      activeIndex === index ? "" : "collapsed"
                    } bg-secondary text-light`}
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target={`#collapse${index}`}
                    aria-expanded={activeIndex === index ? "true" : "false"}
                    aria-controls={`collapse${index}`}
                    onClick={() => setActiveIndex(index)}
                  >
                    {benefit.title}
                  </button>
                </h2>
                <div
                  id={`collapse${index}`}
                  className={`accordion-collapse collapse ${
                    activeIndex === index ? "show" : ""
                  }`}
                  aria-labelledby={`heading${index}`}
                  data-bs-parent="#benefitsAccordion"
                >
                  <div className="accordion-body">
                    <p>{benefit.short_description}</p>
                    {/* On small screens show the full details */}
                    <div className="d-md-none">
                      <img
                        src={benefit.image}
                        alt={benefit.title}
                        style={{ width: "50%" }}
                        className="img-fluid rounded my-3"
                      />
                      <p>{benefit.description}</p>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>

        {/* Full description and image on larger screens */}
        <div className="col-md-6 d-none d-md-block">
          <div className="bg-secondary rounded-4 p-4">
            <h3 className="text-light">{benefits[activeIndex].title}</h3>
            <p className="text-light">{benefits[activeIndex].description}</p>
            <div className="d-flex justify-content-end">
              <img
                alt={benefits[activeIndex].title}
                src={benefits[activeIndex].image}
                className="img-fluid rounded"
                style={{ width: "40%" }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
