import React from "react";
import BackupCodesVisual from "../../../../assets/features/backupcodes.png";

export default function BackupCodes() {
  const backupcodes = [
    {
      id: 1,
      code: "27ad3672yhdkso",
    },
    {
      id: 2,
      code: "632skd8278dsha",
    },
    {
      id: 3,
      code: "984sjakldj938e3",
    },
    {
      id: 4,
      code: " 74shdj2778shfhjs",
    },
    {
      id: 5,
      code: " y764837dhgcch2",
    },
    {
      id: 6,
      code: " dhgsajdgh28731",
    },
    {
      id: 7,
      code: " dkhgd22187371s",
    },
    {
      id: 8,
      code: " 746186sdshdk23",
    },
    {
      id: 9,
      code: " idsajh2736169sd",
    },
    {
      id: 10,
      code: " 412761876sdhsd",
    },
  ];

  return (
    <div className="bg-secondary p-2 rounded-4 position-relative">
      <h4>Backup codes</h4>
      <p>
        Generate one-time backup codes for users, ensuring they can access their
        accounts.
      </p>
      <div className="row  p-1">
        <div className="col-12">
          <div
            className="row mt-1 gap-1"
            style={{
              width: "100%",
            }}
          >
            {backupcodes.map((backupcode) => (
              <div
                key={backupcode.id}
                style={{
                  fontSize: "0.7rem",
                  padding: "0.5rem",
                }}
                className="col-5 bg-primary rounded-3 text-tertiary"
              >
                {backupcode.code}
              </div>
            ))}
          </div>
        </div>
        <div
          style={{
            bottom: "0",
            right: "30px",
          }}
          className="col-3 position-absolute  d-flex flex-column justify-content-end align-items-start"
        >
          <img
            style={{
              width: "130px",
            }}
            src={BackupCodesVisual}
          />
        </div>
      </div>
    </div>
  );
}
