import React from "react";
import StrapiLogo from "../../../../assets/usps/exposed/strapilogo.png";
import Hacker from "../../../../assets/usps/exposed/hacker.png";
import Warning from "../../../../assets/usps/exposed/warning.png";
import MessageBubble from "../../../utils/MessageBubble";
import { motion } from "framer-motion";

export default function Exposed({ active }) {
  // Define motion variants for reusable animation properties
  const fadeVariants = {
    hidden: { opacity: 0, transition: { duration: 0.7, ease: "easeInOut" } },
    visible: { opacity: 1, transition: { duration: 0.7, ease: "easeInOut" } },
  };

  return (
    <motion.div
      animate={{
        backgroundColor: active === 1 ? "#25293C" : "#2F3349",
      }}
      transition={{ duration: 0.7, ease: "easeInOut", delay: 0 }}
      style={{
        maxHeight: "600px",
      }}
      className={`d-flex justify-content-center align-items-center flex-column h-100 rounded-4`}
    >
      <motion.div
        className="rounded-4"
        style={{
          position: "absolute",
          top: "0px",
          left: "0px",
          backgroundColor: "#2F3349",
          width: "100%",
          height: "100%",
          opacity: 1,
        }}
        animate={active === 1 ? "hidden" : "visible"}
        variants={fadeVariants}
      />

      <motion.img
        src={Warning}
        alt="Warning"
        className="position-absolute"
        style={{
          top: "0px",
          left: "0px",
          zIndex: 13333,
        }}
        animate={active === 1 ? "visible" : "hidden"}
        variants={fadeVariants}
      />

      <motion.img
        src={StrapiLogo}
        alt="Strapi Logo"
        animate={active === 1 ? "visible" : "hidden"}
        variants={fadeVariants}
      />

      <motion.div
        className="mt-2"
        style={{
          width: "300px",
        }}
        animate={active === 1 ? "visible" : "hidden"}
        variants={fadeVariants}
      >
        <h4 className="text-light fw-normal text-center">Welcome to Strapi</h4>
        <p className="text-center"> Log in to your Strapi account </p>
        <form className="d-flex flex-column mt-2">
          <label className="text-light">Email</label>
          <input
            type="text"
            placeholder="What is your email?"
            className="form-control"
          />
          <label className="text-light mt-1">Password</label>
          <input
            type="password"
            placeholder="What is your password?"
            className="form-control mt-1"
          />
          <div className="d-flex gap-1 align-items-center py-2">
            <input type="checkbox" className="form-check-input" />
            <label className="text-light">Remember me</label>
          </div>
        </form>
      </motion.div>

      {active === 1 && (
        <>
          <motion.div
            className="position-absolute"
            style={{ bottom: "30px", right: "80px", zIndex: "1" }}
            animate={active === 1 ? "visible" : "hidden"}
            variants={fadeVariants}
          >
            <MessageBubble
              message={`You've been hacked muahahahaha 😈😈😈😈😈`}
            />
          </motion.div>

          <motion.img
            src={Hacker}
            alt="Hacker"
            className="position-absolute"
            style={{
              bottom: "-60px",
              right: "-50px",
              width: "250px",
            }}
            animate={active === 1 ? "visible" : "hidden"}
            variants={fadeVariants}
          />
        </>
      )}
    </motion.div>
  );
}
