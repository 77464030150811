import React from "react";
import Sidebar from "../../../../assets/usps/exposed/sidebar.png";
import Congrats from "../../../../assets/usps/exposed/congrats.png";
import styles from "../usp.module.scss";
import { motion } from "framer-motion";

export default function Solution({ active }) {
  const fadeVariants = {
    hidden: { opacity: 0, transition: { duration: 0.7, ease: "easeInOut" } },
    visible: { opacity: 1, transition: { duration: 0.7, ease: "easeInOut" } },
  };

  return (
    <motion.div
      animate={{
        backgroundColor: active === 3 ? "#25293C" : "#2F3349",
      }}
      transition={{ duration: 0.8, ease: "easeOut", delay: 0 }}
      className={`d-flex position-relative justify-content-center bg-primary h-100 rounded-4 overflow-hidden  ${
        active ? styles.active : ""
      }`}
    >
      <motion.div
        className="rounded-4"
        style={{
          position: "absolute",
          top: "0px",
          left: "0px",
          backgroundColor: "#2F3349",
          width: "100%",
          height: "100%",
          opacity: 1,
        }}
        animate={active === 3 ? "hidden" : "visible"}
        variants={fadeVariants}
      />

      <motion.div
        className="row w-100"
        style={{ maxWidth: "900px", maxHeight: "400px" }}
        animate={active === 3 ? "visible" : "hidden"}
        variants={fadeVariants}
      >
        <motion.div
          className="col-lg-4 col-md-4 col-sm-2 p-0"
          animate={active === 3 ? "visible" : "hidden"}
          variants={fadeVariants}
        >
          <img src={Sidebar} alt="Sidebar" className={`img-fluid`} />
        </motion.div>

        <motion.div
          className="col-lg-8 col-md-8 col-sm-10 d-flex flex-column align-items-start px-4 py-2"
          animate={active === 3 ? "visible" : "hidden"}
          variants={fadeVariants}
        >
          <motion.img
            src={Congrats}
            alt="Congratulations"
            className="mb-4"
            style={{ width: "100px" }}
            animate={active === 3 ? "visible" : "hidden"}
            variants={fadeVariants}
          />

          <motion.h3
            className="text-white mb-2"
            animate={active === 3 ? "visible" : "hidden"}
            variants={fadeVariants}
          >
            Congratulations!
          </motion.h3>

          <motion.p
            className="text-white mb-0"
            style={{ fontSize: "14px" }}
            animate={active === 3 ? "visible" : "hidden"}
            variants={fadeVariants}
          >
            You have successfully secured your system with Multifactor
            Authentication (MFA). Now, your system is protected from
            unauthorized access, brute-force, phishing, and other attacks.
          </motion.p>
        </motion.div>
      </motion.div>
    </motion.div>
  );
}
