import React from "react";
import styles from "./hero.module.scss";
import { Link } from "@remix-run/react";

export default function Cta() {
  return (
    <div className={`w-75 ${styles.cta}`}>
      <span class="badge text-white badge-tertiary p-2">
        In closed beta now
      </span>

      <h1>
        Unlock Ultimate  Security for Your  {" "}
        <span className="text-tertiary">Strapi</span> APIs.
      </h1>
      <p className="mt-2">
        {" "}
        The only plug & play Multi-Factor Authentication solution built
        exclusively for Strapi. Effortless to install. Seamless to scale.
        Unmatched protection.{" "}
      </p>
      <div className="d-flex gap-1 mt-2">
        <Link to="/#learn-more" className="btn btn-tertiary">
          Learn More
        </Link>
      </div>
    </div>
  );
}
