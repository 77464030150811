import React from "react";
import styles from "../features.module.scss";
import DiscordLogo from "../../../../assets/features/discordlogo.png";
import GoogleLogo from "../../../../assets/features/google.png";
import FacebookLogo from "../../../../assets/features/facebook.svg";
import HeadlockrLogo from "../../../../assets/features/headlockr.png";

export default function Totp() {
  const totps = [
    {
      id: 1,
      name: "Discord",
      email: "info@strapi.io",
      code: "123456",
      icon: DiscordLogo,
    },
    {
      id: 2,
      name: "Google",
      email: "sophia@moving-heads.io",
      code: "654321",
      icon: GoogleLogo,
    },
    {
      id: 3,
      name: "Facebook",
      email: "Robert@exlusiverealestate.com",
      code: "987654",
      icon: FacebookLogo,
    },
    {
      id: 4,
      name: "Headlockr",
      email: "Richard@tigertech.com",
      code: "456789",
      icon: HeadlockrLogo,
    },
  ];

  return (
    <div className="bg-secondary p-2 rounded-4">
      <h4>Authentication with TOTP</h4>
      <p className="my-1">
        Add an extra layer of security to your Strapi <br /> instance with
        time-based one-time passwords.{" "}
      </p>
      {totps.map((totp) => (
        <div
          key={totp.id}
          className={`row bg-primary py-1 rounded-3 mt-1 justify-content-between ${styles.totptext}`}
        >
          <div className="col-4 d-flex gap-1 align-items-center">
            <img
              style={{
                width: "20px",
              }}
              src={totp.icon}
              alt={totp.name}
            />
            <p className="mb-0">{totp.name}</p>
          </div>

          <div className="col-4 d-flex align-items-center">
            <p className="mb-0">{totp.email}</p>
          </div>
          <div className="col-4 text-end d-flex align-items-center justify-content-end">
            <p className="mb-0">{totp.code}</p>
          </div>
        </div>
      ))}
    </div>
  );
}
