import React from "react";
import styles from "../../../*.module.scss";
import Imac from "../../../../assets/devices/imac.png";
import Ipad from "../../../../assets/devices/ipad.png";
import Iphone from "../../../../assets/devices/phone.png";
import Macbook from "../../../../assets/devices/macbook.png";

export default function Multidevice() {
  const devices = [
    { name: "Imac Peter", icon: Imac },
    { name: "Ipad Anna", icon: Ipad },
    { name: "Iphone John", icon: Iphone },
    { name: "Macbook Jane", icon: Macbook },
  ];

  return (
    <div className="bg-secondary p-2 rounded-4 h-100">
      <h4>Multi-device compatibility</h4>
      <p>Support multiple devices </p>
      {devices.map((device) => (
        <div
          key={device.name}
          className={`row bg-primary py-1 rounded-3 mt-1  ${styles.totptext}`}
        >
          <div className="col-12 d-flex gap-1 align-items-center">
            <img alt={device.name} src={device.icon} width={20} />
            <p
              className="mb-0"
              style={{
                fontSize: "0.8rem",
              }}
            >
              {device.name}
            </p>
          </div>
        </div>
      ))}
    </div>
  );
}
