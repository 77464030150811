import React from "react";
import SmsImage from "../../../../assets/features/sms.png";

export default function Sms() {
  return (
    <div className="bg-secondary px-2 pt-2 rounded-4 position-relative">
      <h4>SMS-based authentication</h4>
      <p className="mb-3">
        Enable SMS verification to send a unique one-time code{" "}
      </p>
      <div className="d-flex justify-content-end">
        <img
          style={{
            width: "100px",
          }}
          src={SmsImage}
          alt="SMS"
        />
      </div>
    </div>
  );
}
