import React from "react";
import BackupCodes from "./set/BackupCodes";
import Sms from "./set/Sms";
import Todo from "./set/Todo";
import Multidevice from "./set/Multidevice";
import Totp from "./set/Totp";

import styles from "./features.module.scss";

export default function Features() {
  return (
    <div className="d-flex justify-content-center pt-5 flex-column">
      <h2 className="text-center mb-5">
        <span className="text-tertiary">Simplify </span> API Security.{" "}
        <span className="text-tertiary">Focus </span> on <br /> Building.{" "}
      </h2>

      <div className="row w-100 mx-auto d-flex align-items-stretch">
        <div className="col-md-4 d-flex flex-column">
          <div className="row h-100">
            <div className={`col-12 mb-3 d-flex align-items-stretch`}>
              <div className="rounded-4 p-1 w-100 h-100 bg-secondary text-light">
                <Sms />
              </div>
            </div>
            <div className={`col-12 d-flex align-items-stretch`}>
              <div className="rounded-4 p-1 w-100 h-100 bg-secondary text-light">
                <BackupCodes />
              </div>
            </div>
          </div>
        </div>

        <div className="col-md-5 d-flex flex-column">
          <div className="row h-100">
            <div
              className={`col-12 mb-3 d-flex align-items-stretch ${styles.feature}`}
            >
              <div className="rounded-4 p-1 w-100 h-100 bg-secondary text-light">
                <Totp />
              </div>
            </div>
            <div className={`col-12 d-flex align-items-stretch`}>
              <div className="rounded-4 p-1 w-100 h-100 bg-secondary text-light">
                <Multidevice />
              </div>
            </div>
          </div>
        </div>

        <div
          className={`col-md-3 d-flex align-items-stretch ${styles.feature}`}
        >
          <div className="rounded-4 p-1 w-100 h-100 bg-secondary text-light">
            <Todo />
          </div>
        </div>
      </div>
    </div>
  );
}
